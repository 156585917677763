import { createBrowserRouter } from "react-router-dom";
import { MAIN_PATH } from "../constant";
import MainLayout from "../layouts/MainLayout";
import HomePage from "../pages/HomePage";
import GenreExplore from "../pages/GenreExplore";
import WatchPage from "../pages/WatchPage";
import ShortContainer from "../pages/shorts/ShortPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: MAIN_PATH.root,
        element: <HomePage />,
      },
      {
        path: "/shorts",
        element: <ShortContainer />
      },
      {
        path: MAIN_PATH.genreExplore,
        children: [
          {
            path: ":genreId",
            element: <GenreExplore />,
          },
        ],
      },
      {
        path: MAIN_PATH.watch,
        element: <WatchPage />,
      },
    ],
  },
]);

export default router;
