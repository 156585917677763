import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import { MainHeader } from "../components/layouts";

export default function MainLayout() {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        bgcolor: "background.default",
      }}
    >
      <MainHeader />
      <Outlet />
    </Box>
  );
}
