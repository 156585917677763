import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import VimJSPlayer from "../components/watch/VimJSPlayer";

function WatchPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { categoryDetail } = location.state;

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <Box
      m={0}
      py={{xs: 8, md: 0}}
      bgcolor={"black"}
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <VimJSPlayer thumbnail={categoryDetail?.video_thumbnail} hlsSource="https://files.streamingly.net/files/bb28bd7c-fb32-4b7c-ae7f-bf03def74abd/8f95d53b-cc47-4d7d-9d24-161d01231014/index.m3u8"/>
      {
        <Box px={{md: 2}} sx={{ position: "absolute", top: {md: 75}, zIndex: 999 }}>
          <Button onClick={handleGoBack}>
            <KeyboardBackspaceIcon />
          </Button>
        </Box>
      }
    </Box>
  );
}

export default WatchPage;
