import { useLoaderData } from "react-router-dom";
import { CustomGenre, Genre } from "../types/Genre";
import { Stack } from "@mui/material";

function GenreExplore() {
  const genre: CustomGenre | Genre | undefined = useLoaderData() as
    | CustomGenre
    | Genre
    | undefined;

  return <Stack m={0} bgcolor={"black"}>GenreExplore</Stack>;
}

export default GenreExplore;
