import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CustomClassNameSetup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import palette from "./theme/palette";
import MainLoadingScreen from "./components/MainLoadingScreen";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import './App.css';

function App() {
  return (
    <ThemeProvider theme={createTheme({ palette })}>
      <RouterProvider router={router} fallbackElement={<MainLoadingScreen />} />
    </ThemeProvider>
  );
}

export default App;
