import { useEffect, useState, useCallback, useRef } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import Player from "video.js/dist/types/player";
import MaxLineTypography from "./MaxLineTypography";
import PlayButton from "./PlayButton";
import NetflixIconButton from "./NetflixIconButton";
import MaturityRate from "./MaturityRate";
import useOffSetTop from "../hooks/useOffSetTop";
import VideoJSPlayer from "./watch/VideoJSPlayer";
import useMediaQuery from "@mui/material/useMediaQuery";

interface Detail {
  title: string;
  overview: string;
  file: string;
  poster: string;
  category: string;
  maturity: string;
}

interface TopTrailerProps {
  category: Detail;
}

export default function TopTrailer({ category }: TopTrailerProps) {
  const [muted, setMuted] = useState(true);
  const playerRef = useRef<Player | null>(null);
  const isOffset = useOffSetTop(window.innerWidth * 0.5625);
  const isMobile = useMediaQuery('(max-width:900px)');
  const sm = useMediaQuery('(max-width:480px)');

  const handleReady = useCallback((player: Player) => {
    playerRef.current = player;
  }, []);

  useEffect(() => {
    if (playerRef.current) {
      if (isOffset) {
        playerRef.current.pause();
      } else {
        if (playerRef.current.paused()) {
          playerRef.current.play();
        }
      }
    }
  }, [isOffset]);

  const handleMute = useCallback((status: boolean) => {
    if (playerRef.current) {
      playerRef.current.muted(!status);
      setMuted(!status);
    }
  }, []);

  return (
    <Box sx={{ position: "relative", zIndex: 1 }}>
      <Box
        sx={{
          mb: 3,
          pb: "40%",
          top: 0,
          left: 0,
          right: 0,
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: {xs: '100vw', sm: "80vw", md:'60.25vw'},
            position: "absolute",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundImage: { xs: "url(https://i.ytimg.com/vi/vhCDSuLqrK8/sd2.jpg)", md: "none"},
          }}
        >
          <>
            <Box
              sx={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                position: "absolute",
              }}
            >
              {!isMobile && (
                <VideoJSPlayer
                  options={{
                    loop: true,
                    muted: true,
                    autoplay: true,
                    controls: false,
                    responsive: true,
                    fluid: true,
                    sources: [
                      {
                        type: "application/x-mpegURL",
                        src: category.file,
                      },
                    ],
                  }}
                  onReady={handleReady}
                />
              )}
              <Box
                sx={{
                  background: `linear-gradient(77deg,rgba(0,0,0,.6),transparent 85%)`,
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: "26.09%",
                  opacity: 1,
                  position: "absolute",
                  transition: "opacity .5s",
                }}
              />
              <Box
                sx={{
                  backgroundColor: "transparent",
                  backgroundImage:
                    "linear-gradient(180deg,hsla(0,0%,8%,0) 0,hsla(0,0%,8%,.15) 15%,hsla(0,0%,8%,.35) 29%,hsla(0,0%,8%,.58) 44%,#141414 68%,#141414)",
                  backgroundRepeat: "repeat-x",
                  backgroundPosition: "0px top",
                  backgroundSize: "100% 100%",
                  bottom: 0,
                  position: "absolute",
                  height: "14.7vw",
                  opacity: 1,
                  top: "auto",
                  width: "100%",
                }}
              />
              <Stack
                direction="row"
                spacing={2}
                display={{xs: "none", md: "flex"}}
                sx={{
                  alignItems: "center",
                  position: "absolute",
                  right: 0,
                  bottom: "35%",
                }}
              >
                <NetflixIconButton
                  size="large"
                  onClick={() => handleMute(muted)}
                  sx={{ zIndex: 2 }}
                >
                  {!muted ? <VolumeUpIcon /> : <VolumeOffIcon />}
                </NetflixIconButton>
                <MaturityRate>{`${category.maturity}+`}</MaturityRate>
              </Stack>
            </Box>

            <Box
              sx={{
                position: "absolute",
                top: `${sm ? "9pc" : 0}`,
                left: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <Stack
                spacing={4}
                sx={{
                  bottom: { xs: 0, md: "35%"},
                  position: { md: "absolute"},
                  left: { md: "60px" },
                  top: 0,
                  alignItems: {xs: "center", md: "flex-start"},
                  width: { md:"42%" },
                  zIndex: 10,
                  justifyContent: "flex-end",
                }}
              >
                <MaxLineTypography
                  variant="h2"
                  fontSize={{xs: "1.8rem" , md: "3.75rem"}}
                  fontWeight="bold"
                  maxLine={1}
                  pt={10}
                  color="text.primary"
                >
                  {category.title}
                </MaxLineTypography>
                { !sm &&
                <MaxLineTypography
                  maxLine={4}
                  px={{xs: 5, md: 0}}
                  textAlign={{xs: "center", md: "start"}}
                  color="text.primary"
                >
                  {category.overview}
                </MaxLineTypography>
                }
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <PlayButton
                    size="large"
                    categoryDetail={category}
                    videoThumbnail={""}
                  />
                  {/* <MoreInfoButton size="large" /> */}
                </Stack>
              </Stack>
            </Box>
          </>
        </Box>
      </Box>
    </Box>
  );
}