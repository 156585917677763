import { useState, useRef } from "react";
import Slider, { Settings } from "react-slick";

import { Theme, styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import CustomNavigation from "./CustomNavigation";
import { ARROW_MAX_WIDTH } from "../../constant";
import { CategoryDeail } from "../../types/Genre";
import VideoItemWithHover from "../VideoItemWithHover";
import { Typography } from "@mui/material";

const RootStyle = styled("div")(() => ({
  position: "relative",
  overflow: "inherit",
}));

interface SlideProps {
  item: CategoryDeail,
  key: number
}

function SlideItem({ item }: SlideProps) {
  return (
    <Box sx={{ pr: { xs: 0.5, sm: 1 } }}>
      <VideoItemWithHover category={item} />
    </Box>
  );
}

const StyledSlider = styled(Slider)(
  ({ theme, padding, lessThanFour }: { theme: Theme; padding: number; lessThanFour: boolean }) => ({
    display: "flex !important",
    justifyContent: "center",
    marginBottom: "2rem",
    overflow: "initial !important",
    "& > .slick-list": {
      overflow: "visible",
    },
    [theme.breakpoints.up("sm")]: {
      "& > .slick-list": {
        width: `calc(100% - ${2 * padding}px)`,
      },
      "& .slick-list > .slick-track": {
        margin: "0px !important",
      },
      "& .slick-list > .slick-track > .slick-current > div > .NetflixBox-root > .NetflixPaper-root:hover":
        {
          transformOrigin: "0% 50% !important",
        },
        "& .slick-track": {
          transform: lessThanFour ? "translate3d(0, 0, 0) !important" : null,
        },
    },
    [theme.breakpoints.down("sm")]: {
      "& > .slick-list": {
        width: `calc(100% - ${padding}px)`,
      },
    },
  })
);

interface SingleCategorySliderProps {
  categoryMovies: CategoryDeail[];
}

function SingleCategorySlider({ categoryMovies }: SingleCategorySliderProps) {
  const sliderRef = useRef<Slider>(null);
  const theme = useTheme();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [isEnd, setIsEnd] = useState(false);

  const lessThanFour = categoryMovies.length < 6;

  const beforeChange = async (currentIndex: number, nextIndex: number) => {
    if (currentIndex < nextIndex) {
      setActiveSlideIndex(nextIndex);
    } else if (currentIndex > nextIndex) {
      setIsEnd(false);
    }
    setActiveSlideIndex(nextIndex);
  };

  const settings: Settings = {
    speed: 500,
    arrows: true, 
    infinite: false,
    lazyLoad: "ondemand",
    slidesToShow: 6,
    slidesToScroll: 6,
    rtl: false,
    beforeChange,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const handlePrevious = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  return (
    <CustomNavigation
      isEnd={isEnd}
      arrowWidth={ARROW_MAX_WIDTH}
      onNext={handleNext}
      onPrevious={handlePrevious}
      activeSlideIndex={activeSlideIndex}
      lessThanFour={lessThanFour}
    >
      <StyledSlider
        theme={theme} 
        ref={sliderRef}
        {...settings}
        padding={ARROW_MAX_WIDTH}
        lessThanFour={lessThanFour}      >
        {categoryMovies.map((movie, index) => (
          <SlideItem key={index} item={movie} />
        ))}
      </StyledSlider>
    </CustomNavigation>
  );
}

interface SlickSliderProps {
  data: Record<string, CategoryDeail[]> | null;
}

export default function SlickSlider({ data }: SlickSliderProps) {
  return (
    <Box sx={{ overflow: "hidden", height: "100%", zIndex: 1 }}>
      {data &&
        Object.entries(data).map(([categoryName, categoryMovies], index) => (
          <div key={index}>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              paddingLeft={{xs: 1, md: 5}}
              sx={{ mb: 2 }}
            >
              {/* <NetflixNavigationLink
                variant="h5"
                to={`/genre/${categoryName.toLowerCase().replace(" ", "_")}`}
                sx={{
                  display: "inline-block",
                  fontWeight: 700,
                }}
              > */}
              <Typography variant="h5" sx={{
                  display: "inline-block",
                  fontWeight: 700,
                  color: "white",
                  textTransform: "capitalize"
                }}>
              {`${categoryName}`}
                </Typography>
                
              {/* </NetflixNavigationLink> */}
            </Stack>

            <RootStyle key={index}>
              <SingleCategorySlider categoryMovies={categoryMovies} />
            </RootStyle>
          </div>
        ))}
    </Box>
  );
}