import Stack from "@mui/material/Stack";
import HeroSection from "../components/HeroSection";

import SlickSlider from "../components/slick-slider/SlickSlider";
import axios from "axios";
import { useEffect, useState } from "react";
import { CategoryDeail } from "../types/Genre";
import MainLoadingScreen from "../components/MainLoadingScreen";
import { STRM_V3_API_KEY } from "../constant";

type ApiResponse = {
  success: boolean;
  data: Record<string, CategoryDeail[]>;
  message: string;
};

export default function HomePage() {
  const [apiData, setApiData] = useState<ApiResponse | null>(null);
  const [heroCategory, setHeroCategory] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://api.streamingly.net/v1/video_stream", {
        headers: {
          Authorization: STRM_V3_API_KEY,
        },
      })
      .then((response) => {
        setApiData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (apiData) {
      const categories = Object.keys(apiData.data);
      if (categories.length > 0) {
        setHeroCategory(categories[0]);
      }
    }
  }, [apiData]);

  return (
    <Stack spacing={{xs: 21, md: 2}} m={0} bgcolor={"black"}>
      {loading && <MainLoadingScreen />}
      {heroCategory && (
        <HeroSection
          category={{
            title: "Emotional Video",
            overview:
              "Streamingly operate a digital platform where users can access a variety of streaming content. This could include a website or mobile app where users can browse, search for, and watch/stream content.",
            file: "https://files.streamingly.net/files/cb306e5f-f6f8-4ae7-bd3f-41d2f86af2ac/0ed0c0e2-7ac5-49db-ae09-cf7a3fc2628a/index.m3u8",
            poster: "https://image.tmdb.org/t/p/original/lU6TnIwgDEs0yJMnAzI3M2yTMLw.jpg",
            category: "string",
            maturity: "8",
          }}
        />
      )}
      {apiData && <SlickSlider data={apiData.data} />}
    </Stack>
  );
}