import * as React from "react";
import { Player, Hls, DefaultUi } from "@vime/react";
import "@vime/core/themes/default.css";
import "./VimPlayer.css";
import useWindowSize from "../../hooks/useWindowSize";

interface IVideoPlayerProps {
  hlsSource: string;
  thumbnail?: string;
  controls?: boolean;
}

const VimJSPlayer: React.FC<IVideoPlayerProps> = ({
  hlsSource,
  thumbnail,
  controls,
}) => {
  const windowSize = useWindowSize();

  return (
    <Player theme="dark" seeking>
      <Hls crossOrigin="use-credentials" version="latest" poster={thumbnail}>
        <source
          width={windowSize.width}
          height={windowSize.height}
          data-src={hlsSource}
          type="application/x-mpegURL"
        />
        <track
          default
          kind="subtitles"
          src="https://media.vimejs.com/subs/english.vtt"
          srcLang="en"
          label="English"
        />
      </Hls>

      <DefaultUi noControls={controls} />
    </Player>
  );
};

export default VimJSPlayer;
