import { ChatBubbleOutline, Favorite, FavoriteBorder, MoreVert, PauseOutlined, PlayArrowOutlined, Share, VolumeMuteOutlined, VolumeUpOutlined } from "@mui/icons-material";
import { Stack, Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";

interface ShortProps {
  short: {
    id: string;
    username: string;
    videoUrl: string;
    isFollowed: boolean;
    title: string;
    profileUrl: string;
    reaction: {
      likes: string;
      comments: string;
      isLiked: boolean;
    };
  };
  shortContainerRef: React.RefObject<HTMLDivElement>;
}

function Short({ short, shortContainerRef }: ShortProps) {
  const playPauseRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [isLiked, setIsLiked] = useState<boolean>(short.reaction.isLiked);

  useEffect(() => {
    const handleVideo = () => {
      if (videoRef.current) {
        const videoTop = videoRef.current.getBoundingClientRect().top;

        if (videoTop > 0 && videoTop <= 150) {
          videoRef.current.play().then(() => {
            setIsPlaying(true);
          }).catch(() => {
            setIsPlaying(false);
            videoRef.current?.pause();
          });
        } else {
          videoRef.current.currentTime = 0;
          videoRef.current.pause();
        }
      }
    };

    if (shortContainerRef.current) {
      shortContainerRef.current.addEventListener("scroll", handleVideo);
    }
    if (videoRef.current) {
      setIsPlaying(!videoRef.current.paused);
      setIsMuted(!!videoRef.current.muted);
    }

    const handleBlur = () => {
      if (isActiveVideo(videoRef)) {
        videoRef.current?.pause();
        setIsPlaying(false);
      }
    };

    const handleFocus = () => {
      if (isActiveVideo(videoRef)) {
        videoRef.current?.play();
        setIsPlaying(true);
      }
    };

     window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);
    return () => {
      if (shortContainerRef.current) {
        shortContainerRef.current.removeEventListener("scroll", handleVideo);
      }
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };

  }, [shortContainerRef]);

  const handleVideoClick = () => {
    if (!videoRef.current) return;
    if (!isPlaying) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const isActiveVideo = (videoRef: React.RefObject<HTMLVideoElement>) => {
    const boundingClientRect = videoRef.current?.getBoundingClientRect();
    return boundingClientRect ? (boundingClientRect.top > 0 && boundingClientRect.top <= 150) : false;
  };

  return (
    <Stack sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "2.6rem",
      scrollSnapAlign: "start",
      ":last-child": {
        paddingBottom: "10rem"
      }
    }}>
      <Box sx={{
        display: "flex",
        position: "relative"
      }}>
        <Box sx={{
          position: "relative",
          height: "80vh",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          borderRadius: "10px",
          maxWidth: "calc((9 / 16) * 80vh)",
          ":hover > .controls": {
            opacity: 1,
            visibility: "visible",
            transform: "translateY(0)"
          },
          ":hover > .foot": {
            background: "rgba(0, 0, 0, 0.7)",
            backdropFilter: "blur(3px)"
          }
        }}>
          <video
            ref={videoRef}
            onClick={handleVideoClick}
            disableRemotePlayback
            playsInline
            loop
            src={short.videoUrl}
          ></video>
          <Box className="controls" sx={{
             position: "absolute",
             top: 0,
             opacity: 0,
             visibility: "hidden",
             padding: "1rem",
             width: "100%",
             display: "flex",
             justifyContent: "space-between",
             boxSizing: "border-box",
             borderRadius: "10px",
             transition: "all 0.2s",
             transform: "translateY(-50px)",
             background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.734), transparent)",
          }}>
            <span
              onClick={handleVideoClick}
            >
              {
                isPlaying ? <PauseOutlined /> : <PlayArrowOutlined />
              }
            </span>
            <span
              onClick={() => {
                if (videoRef.current) {
                  videoRef.current.muted = !isMuted;
                  setIsMuted(!isMuted);
                }
              }}
            >
              {
                isMuted ? <VolumeMuteOutlined color="primary" /> : <VolumeUpOutlined color="primary"/>
              }
            </span>
          </Box>
          <div
            ref={playPauseRef}
            onClick={() => {
              if (videoRef.current) {
                videoRef.current.play();
                setIsPlaying(true);
              }
            }}
            className={`play-pause ${isPlaying ? "" : "show-play-pause"}`}
          >
            {
              isPlaying ? <PauseOutlined color="primary" /> : <PlayArrowOutlined  color="primary"/>
            }
          </div>
          <Box className="foot" sx={{
             position: "absolute",
             bottom: "0",
             width: "100%",
             padding: "1rem",
             boxSizing: "border-box",
             transition: "all 0.2s",
             borderRadius: "10px"
          }}>
            <div className="title">{short.title}</div>
            <div className="user-info">
              <div>
                <img src="https://png.pngtree.com/element_pic/00/16/10/1658038de19d7f5.png" alt="" />
                <span>{short.username}</span>
              </div>
            </div>
          </Box>
        </Box>
        <div className="reaction">
          <div
            style={{color: "whitesmoke"}}
            onClick={() => {
              setIsLiked(!isLiked);
            }}
          >
            {isLiked ? (
              <span className="like">
                <Favorite sx={{zIndex: 9999, cursor: "pointer"}}/>
              </span>
            ) : (
              <span className="unlike">
                <FavoriteBorder sx={{zIndex: 9999, cursor: "pointer"}}/>
              </span>
            )}

            <span className="value">{short.reaction.likes}</span>
          </div>
          <div style={{color: "whitesmoke"}}>
            <ChatBubbleOutline sx={{zIndex: 9999, cursor: "pointer"}}/>
            <span className="value">{short.reaction.comments}</span>
          </div>
          <div style={{color: "whitesmoke"}}>
            <Share sx={{zIndex: 9999, cursor: "pointer"}}/>
          </div>
          <div style={{color: "whitesmoke"}}>
            <MoreVert sx={{zIndex: 9999, cursor: "pointer"}}/>
          </div>
        </div>
      </Box>
    </Stack>
  );
}

export default Short;