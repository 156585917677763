import { useState, useRef } from "react";
import VideoItemWithHoverPure from "./VideoItemWithHoverPure";
import { CategoryDeail } from "../types/Genre";
import { useNavigate } from "react-router-dom";
import { MAIN_PATH } from "../constant";
interface VideoItemWithHoverProps {
  category: CategoryDeail;
}

export default function VideoItemWithHover({ category }: VideoItemWithHoverProps) {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <VideoItemWithHoverPure
      ref={elementRef}
      handleHover={setIsHovered}
      imageClick={()=> navigate(`/${MAIN_PATH.watch}`, { state: { categoryDetail: category } })}
      src={category?.video_thumbnail ? category?.video_thumbnail : "https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg"}
    />
  );
}
