import { useRef } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { ArrowDownward } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import Short from "./Short";

interface ShortData {
  id: string;
  username: string;
  videoUrl: string;
  isFollowed: boolean;
  title: string;
  profileUrl: string;
  reaction: {
    likes: string;
    comments: string;
    isLiked: boolean;
  };
}


function ShortContainer() {
  const shortContainerRef = useRef<HTMLDivElement | null>(null);

  const data: ShortData[] = [
    {
      id: "1",
      username: "Popular",
      videoUrl:
        "https://github.com/hicodersofficial/images/blob/main/zach.mp4?raw=true",
      isFollowed: true,
      title: "Trying to help speed up the supply chain",
      profileUrl:
        "https://yt3.ggpht.com/ytc/AMLnZu_I4_Paw3h1eM6ASNhPpcfK4-E12zY7JSb7dn0iLA=s176-c-k-c0x00ffffff-no-rj-mo",
      reaction: {
        likes: "1.1M",
        comments: "40K",
        isLiked: true,
      },
    },
    {
      id: "2",
      username: "Annoucments",
      videoUrl:
        "https://github.com/hicodersofficial/images/blob/main/khaby1.mp4?raw=true",
      isFollowed: false,
      title:
        "Bro Jasonderulo it’s not my fault 🥹 please forgive me 🙏🏿🫶🏿 #shorts #khabylame #funny",
      profileUrl:
        "https://yt3.ggpht.com/-ieEoBkfKwx42qXBFbJy1L4BgBXMUOum85q9XJQMBzi50gWI8HRTT_ADsKdyufECc78DtMnG-Q=s176-c-k-c0x00ffffff-no-rj",
      reaction: {
        likes: "100K",
        comments: "10K",
        isLiked: false,
      },
    },
    {
      id: "3",
      username: "New Coming",
      videoUrl:
        "https://github.com/hicodersofficial/images/blob/main/younes.mp4?raw=true",
      isFollowed: false,
      title: "stay hydrated this summer 🍉🍉🍉 #yzfamily",
      profileUrl:
        "https://yt3.ggpht.com/ytc/AMLnZu-TCFbN4LcWK7NVStpW_gXGHO2O-v7cRJM7hFsjKA=s176-c-k-c0x00ffffff-no-rj-mo",
      reaction: {
        likes: "600K",
        comments: "20K",
        isLiked: true,
      },
    },
    {
      id: "4",
      username: "What's new",
      videoUrl:
        "https://github.com/hicodersofficial/images/blob/main/khaby2.mp4?raw=true",
      isFollowed: false,
      title: "Welcome to my family bro, now your life will be simple 👓 🥸😎",
      profileUrl:
        "https://yt3.ggpht.com/-ieEoBkfKwx42qXBFbJy1L4BgBXMUOum85q9XJQMBzi50gWI8HRTT_ADsKdyufECc78DtMnG-Q=s176-c-k-c0x00ffffff-no-rj",
      reaction: {
        likes: "700K",
        comments: "30K",
        isLiked: false,
      },
    },
    {
      id: "5",
      username: "Trending",
      videoUrl:
        "https://github.com/hicodersofficial/images/blob/main/figma%20loader.mp4?raw=true",
      isFollowed: false,
      title: "✨ Figma loading animation! 😍🌈",
      profileUrl: "https://avatars.githubusercontent.com/u/69384657?v=4",
      reaction: {
        likes: "50K",
        comments: "1K",
        isLiked: true,
      },
    },
  ];

  return (
    <>
      <Box
        ref={shortContainerRef}
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          height: "100vh",
          paddingTop: "5rem",
          scrollPaddingTop: "5rem",
          scrollSnapType: "y mandatory",
          overflowY: "scroll",
          scrollBehavior: "smooth",
          WebkitScrollSnapType: "y mandatory",
          msScrollSnapType: "y mandatory",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {data.map((short) => (
          <Short
            key={short.id}
            shortContainerRef={shortContainerRef}
            short={short}
          />
        ))}
      </Box>

      <Stack
        sx={{
          display: "flex",
          zIndex: 9999,
          height: "596vh",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "1rem",
          boxSizing: "border-box",
          marginRight: "2rem",
          ".div": {
            width: "3.5rem",
            height: "3.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.5rem",
            background: "#15161c",
            cursor: "pointer",
            borderRadius: "50%",
            backdropFilter: "blur(20px)",
            border: "1px solid #63646a4f",
          },
        }}
      >
        <div
          onClick={() => {
            if (shortContainerRef.current)
              shortContainerRef.current.scrollTo(
                0,
                shortContainerRef.current.scrollTop - window.innerHeight
              );
          }}
        >
          <ArrowUpwardIcon sx={{ zIndex: 9999, cursor: "pointer" }} />
        </div>
        <div
          style={{
            marginTop: "5rem",
          }}
          onClick={() => {
            if (shortContainerRef.current)
              shortContainerRef.current.scrollTo(
                0,
                shortContainerRef.current.scrollTop + window.innerHeight
              );
          }}
        >
          <ArrowDownward sx={{ zIndex: 9999, cursor: "pointer" }} />
        </div>
      </Stack>
    </>
  );
}

export default ShortContainer;
